import React from "react";
import { useTranslation } from "react-i18next";
import '../Styles/Terms/terms.css'
import Logo from '../Images/logo.png'

const Terms = () => {

    const {t} = useTranslation()

    return(

        <React.Fragment>
            <div className="bsec-1">

                <h1>{t('Terms')}</h1>

            </div>

            <div className="termsinfo">
                <h1 style={{textAlign: t('alignment')}}>{t('T1')}</h1>
                <p style={{textAlign: t('alignment')}}>{t('T2')}</p>
                <p style={{textAlign: t('alignment')}}>{t('T3')}</p>

                <h1 style={{textAlign: t('alignment')}}>{t('T4')}</h1>
                <p style={{textAlign: t('alignment')}}>{t('T5')}</p>
                <img src={Logo}/>
            </div>
        </React.Fragment>
    )
}

export default Terms