import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

const Payment = ({fun}) => {

  return (
    <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_ID, currency: 'ILS', locale: 'he_IL'}}>
      <PayPalButtons
      fundingSource='card'
      className='paybtn'
        createOrder={(data, actions) => {
          
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: 100
              }
            }],
            application_context: {
                shipping_preference: 'NO_SHIPPING'
            }
          })
        }
      }
      onApprove={(data, actions) => {
       
        return actions.order.capture().then(function(details) {
          
          console.log('Payment approved:', details);
          fun()
           
        });
      }}
      />
    </PayPalScriptProvider>
  );
};

export default Payment;