import React, {useState, useEffect} from "react";
import '../Styles/Home/popup.css'
import { Fade } from "react-reveal";
import { FaTimes, FaLanguage } from "react-icons/fa";
import LanguageSwitcher from "../translation/LanguageSwitcher";
import Logo from '../Images/logo2.png'

const LanguagePopuo = (props) => {
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    const closePopup = () => {
        setIsPopupVisible(false);
        localStorage.setItem('popupVisible', false);
    }

    const openPopup = () => {
        setIsPopupVisible(true);
        localStorage.setItem('popupVisible', true);
    }

    useEffect(() => {
        const popupVisible = localStorage.getItem('popupVisible');
        if (popupVisible === null) {
            setIsPopupVisible(true);
        } else {
            setIsPopupVisible(JSON.parse(popupVisible));
        }
    }, []);

    return (isPopupVisible) ? (
        <div className="popup">
            <Fade top >
            <div className="popup-inner">
                <button className="close-btn" onClick={closePopup} setTrigger={openPopup}><FaTimes size={25} color="#fff"/></button>

                <h1 className="ht">Select Language</h1>

                <div className="images">
                <img src={Logo} alt="logo" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                <h1>--------</h1>
                <FaLanguage size={55} color="#fff"/>
                </div>
                <LanguageSwitcher fun={closePopup}/>

                {props.children}
                <p>Powered By <a href="https://devstation.pro/" target=".blank">devstation.pro</a></p>
            </div>
            </Fade>
        </div>
    ) : '';
}

export default LanguagePopuo