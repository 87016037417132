import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import { useNavigate } from "react-router-dom";
import '../Styles/Book/bsec-1.css'
import { Fade } from "react-reveal";
import { useTranslation } from "react-i18next";

const AdminBook = () => {

    const nav = useNavigate()
    const {t} = useTranslation()

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [service, setService] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [availableTimes, setAvailableTimes] = useState([]);
  const [isChecked, setIsChecked] = useState(true);

  const serviceOptions = [t('S1'), t('S2'), t('S3'), t('S4'), t('S5'), t('S6'), t('S7'),
                          t('S8'), t('S9'), t('S10'), t('S11'), t('S12'), t('S13'), t('S14')];

useEffect(() => {
  const fetchAvailableTimes = async () => {
    if (!date) {
      return;
    }

    const dayOfWeek = new Date(date).getDay();

    if (dayOfWeek === 10 || dayOfWeek === 10) {
      setAvailableTimes([]);
      return;
    }

    const snapshot = await db.collection('bookings').where('date', '==', date).get();

    const bookedTimes = snapshot.docs.map((doc) => doc.data().time);

    const morningStartHour = 9;
    const morningEndHour = 14;
    const eveningStartHour = 18;
    const eveningEndHour = 21;
    const interval = 2;

    const allTimes = [];

    for (let i = morningStartHour; i < morningEndHour; i += interval) {
      const hours = String(i).padStart(2, '0');
      allTimes.push(`${hours}:00`);
    }

    for (let i = eveningStartHour; i < eveningEndHour; i += interval) {
      const hours = String(i).padStart(2, '0');
      allTimes.push(`${hours}:00`);
    }

    const freeTimes = allTimes.filter((t) => !bookedTimes.includes(t));
    setAvailableTimes(freeTimes);
  };

  if (date) {
    fetchAvailableTimes();
  }
}, [date]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!name || !phoneNumber || !service || !date || !time) {
      alert(t('A1'));
      return;
    }
  
    if (!date) {
      alert(t('A2'));
      return;
    }
  
    if (availableTimes.length === 0) {
      alert(t('A3'));
      return;
    }
  
    if (!time) {
      alert(t('A4'));
      return;
    }
  
    const timeTaken = await checkTimeAvailability(date, time);
  
    if (!timeTaken) {
    } else {
      alert(t('A5'));
    }
  };

  const checkTimeAvailability = async (selectedDate, selectedTime) => {
    const snapshot = await db
      .collection('bookings')
      .where('date', '==', selectedDate)
      .where('time', '==', selectedTime)
      .get();

    return !snapshot.empty;
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const sendData = () => {
    db.collection('bookings').add({
      name,
      phoneNumber,
      service,
      date,
      time,
    });

    setName('');
    setPhoneNumber('');
    setService('');
    setDate('');
    setTime('');

    alert('Booking successful! 👍');

    setTimeout(() => {
      
    }, 2000);
  }

  const convertToInternationalFormat = (inputPhoneNumber) => {
    let formattedPhoneNumber = inputPhoneNumber.startsWith('0')
      ? inputPhoneNumber.substring(1)
      : inputPhoneNumber;
  
    return formattedPhoneNumber.startsWith('+972') ? formattedPhoneNumber : `+972${formattedPhoneNumber}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = convertToInternationalFormat(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };
  

    return(
        <React.Fragment>

           <div className="bsec-1">

                <h1>Admin</h1>

            </div>

            <Fade duration={2000}>

            <div className="bsec-2">

              <h1>Add New</h1>

            <form onSubmit={handleSubmit}>
            
            <input placeholder={t('Name')} type="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />

            <input
          placeholder={t('Phone')} type="tel" name="phone" value={phoneNumber} onChange={handlePhoneNumberChange} pattern="[0-9]{10}" required/>

            <select placeholder="Service" value={service} onChange={(e) => setService(e.target.value)} required>
              <option value="" disabled>{t('Select')}</option>
              {serviceOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <input placeholder="Date" type="date" value={date} onChange={(e) => setDate(e.target.value)} lang="en-IL" pattern="\d{2}-\d{2}-\d{4}" required />

            <select value={time} onChange={(e) => setTime(e.target.value)} required>
              <option value="" disabled>{t('Time')}</option>
              {availableTimes.map((t) => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </select>

        </form>

        <div className="terms">
            <input type="checkbox" onChange={handleChange} checked={isChecked}/>
            <button onClick={()=>{nav('/Terms')}}>{t('Terms')}</button>
            </div>

            { isChecked && <button onClick={sendData} type="submit">add</button>}

            </div>
            </Fade>
            
        </React.Fragment>
    )

}

export default AdminBook