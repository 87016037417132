import React, {useRef, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import LanguagePopuo from "./LanguagePopup";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser'

// images
import Logo from '../Images/logo.png'
import sec_1 from '../Images/sec-1img.png'
import balkees from '../Images/ADAM8887.jpg'
import {FaBars, FaTimes, FaPhone, FaWaze, FaInstagram, FaWhatsapp, FaTiktok, FaLanguage} from 'react-icons/fa'

import i9874 from '../Images/services/ADAM9874.jpg'
import i9868 from '../Images/services/ADAM9868.jpg'
import i9597 from '../Images/services/ADAM9597.jpg'
import i9539 from '../Images/services/ADAM9593.jpg'
import i9591 from '../Images/services/ADAM9591.jpg'
import i9589 from '../Images/services/ADAM9589.jpg'
import i9518 from '../Images/services/ADAM9518.jpg'
import i9531 from '../Images/services/ADAM9531.jpg'
import i9564 from '../Images/services/ADAM9564.jpg'
import i9565 from '../Images/services/ADAM9565.jpg'
import i9692 from '../Images/services/ADAM9692.jpg'
import i9700 from '../Images/services/ADAM9700.jpg'
import i9702 from '../Images/services/ADAM9702.jpg'
import i9848 from '../Images/services/ADAM9848.jpg'
import i9879 from '../Images/services/ADAM9879.jpg'
import i9880 from '../Images/services/ADAM9880.jpg'

// Styles
import '../Styles/Navbar.css'
import '../Styles/Home/sec-1.css'
import '../Styles/Home/sec-2.css'
import '../Styles/Home/sec-3.css'
import '../Styles/Home/sec-4.css'
import '../Styles/Home/sec-5.css'
import ImageSlider from './Images';

// animations
import { Fade } from "react-reveal";

const Home = () => {


  const {t} = useTranslation()

    const [buttonText, setButtonText] = useState(t('Book'));
    const [popup, setPopup] = useState(false)

    const nav = useNavigate()
    const navRef = useRef()
    const form = useRef()

    useEffect(() => {

      setPopup(true)
        const handleResize = () => {
          if (window.innerWidth <= 1000) {
            setButtonText('B.glamour');
          } else {
            setButtonText(t('Book'));
          }
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    }

    const Services = () => {
      const section = document.getElementById('sec-2')

      section.scrollIntoView({behavior: 'smooth'})
      showNavbar()
    }

    const About = () => {
      const section = document.getElementById('sec-3')

      section.scrollIntoView({behavior: 'smooth'})
      showNavbar()
    }

    const Contacts = () => {
      const section = document.getElementById('sec-4')

      section.scrollIntoView({behavior: 'smooth'})
      showNavbar()
    }

    const changeLanguage = () => {
      localStorage.setItem('popupVisible', true);
      showNavbar()
      window.location.reload()
    }

    const sendEmail = (e) => {
      e.preventDefault();
    
      emailjs.sendForm(process.env.REACT_APP_SID_ID, process.env.REACT_APP_TID_ID, form.current, process.env.REACT_APP_UID_ID)
        .then((result) => {
            console.log(result.text);
            alert("thank you")
        }, (error) => {
            console.log(error.text);
            alert("sorry! try again")
        });
    };

    const sliderImages1 = [
       i9874  ,  i9868 ,  i9597 ,  i9539 ,  i9591 
    ];
  
    const sliderImages2 = [
       i9589 ,  i9518 ,  i9531 ,  i9564 ,  i9565 
    ];

    const sliderImages4 = [
      i9692  ,  i9700 ,  i9702 ,  i9848 ,  i9879 ,  i9880 
    ];

    return (
        <React.Fragment>

          <LanguagePopuo trigger={popup} setTrigger={setPopup}/>

            <header>
            <img src={Logo} alt='header logo' onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

            <nav className='navbar' ref={navRef}>
                <div className='innav'>
                <img src={Logo} alt='header logo' onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                <button onClick={showNavbar} className='navbtn navhide'>
                    <FaTimes color="#ffb4b4"/>
                </button>
                </div>
                <button onClick={Services}>{t('Services')}</button>
                <button onClick={About}>{t('Aboutme')}</button>
                <button onClick={Contacts}>{t('Contact')}</button>
                <button onClick={changeLanguage}><FaLanguage size={30}/></button>
            </nav>
            <button className="booknow" onClick={() => {nav('Book')}}>{buttonText}</button>
            <button onClick={showNavbar} className='navbtn navshow'>
                <FaBars color="#ffb4b4"/>
            </button>
        </header>

        <section className="sec-1">

          <div className="block1">
            
            <h1>B.Glamour Skincare</h1>
            <h1>by <h1>Balkees.</h1></h1>

            <button onClick={() => {nav('Book')}}>{t('Book')}</button>
            <div className="socialmedia">

            <a href="tel:+972507750600"><FaPhone  size={30} color="#ffb4b4"/></a>
            <a href="https://www.instagram.com/b.glamour_skincare/"><FaInstagram size={40} color="#ffb4b4"/></a>
            <a href="https://wa.me/+972507750600"><FaWhatsapp size={40} color="#ffb4b4"/></a>
            <a href="https://www.tiktok.com/@b.glamour.bybalkees"><FaTiktok size={40} color="#ffb4b4"/></a>
            <a href="https://www.waze.com/live-map/directions?to=ll.33.27021843%2C35.7770462"><FaWaze size={40} color="#ffb4b4"/></a>

          </div>

          </div>

          <div className="block2">

            <img src={sec_1} alt="i1" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

          </div>
          
        </section>

        <section className="sec-2" id="sec-2">

          <Fade bottom duration={2000}>

          <h1>{t('Services')}</h1>

          <div className="inservices">

          <Fade bottom duration={1500}>

            <div>
              
              <ImageSlider images={sliderImages1} />

              <h1>{t('S1')}</h1>

            </div>

            <div>
            <ImageSlider images={sliderImages4} />

              <h1>{t('S5')}</h1>
              
            </div>

            <div>
            <ImageSlider images={sliderImages2} />

              <h1>{t('S4')}</h1>
              
            </div>

          </Fade>

          </div>

          <button onClick={() => {nav('Services')}}>{t('Viewmore')}</button>
          </Fade>
        </section>

        <section className="sec-3" id="sec-3">

        <Fade bottom duration={2000}>

          <img src={balkees} alt="i1" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} draggable="false"/>

          </Fade>

          <div className="block3">
            <Fade bottom duration={2000}>

            <h1 style={{textAlign: t('alignment'), width: '100%'}}>{t('Aboutme')}</h1>

            </Fade>

            <Fade bottom duration={2500}>
            <p style={{textAlign: t('alignment')}}>{t('AboutP')}</p>
            </Fade>

            <Fade bottom duration={2500}>
          <div className="socialmedia">

            <a href="https://www.instagram.com/b.glamour_skincare/"><FaInstagram size={40} color="#ffb4b4"/></a>
            <a href="https://wa.me/+972507750600"><FaWhatsapp size={40} color="#ffb4b4"/></a>
            <a href="https://www.tiktok.com/@b.glamour.bybalkees"><FaTiktok size={40} color="#ffb4b4"/></a>

          </div>

          </Fade>

          </div>

        </section>

        <section className="sec-4" id="sec-4">

        <Fade left duration={2500}>

          <div className="block4">

            <h1 style={{textAlign: t('alignment'), width: '100%'}}>{t('Contact')}</h1>

            <p style={{textAlign: t('alignment')}}>{t('ContactP')}</p>

            <div className="socialmedia" style={{justifyContent: t('justify')}}>

            <a href="https://www.instagram.com/b.glamour_skincare/"><FaInstagram size={40} color="#ffb4b4"/></a>
            <a href="https://wa.me/+972507750600"><FaWhatsapp size={40} color="#ffb4b4"/></a>
            <a href="https://www.tiktok.com/@b.glamour.bybalkees"><FaTiktok size={40} color="#ffb4b4"/></a>
            <a href="https://www.waze.com/live-map/directions?to=ll.33.27021843%2C35.7770462" target=".blank"><FaWaze size={40} color="#ffb4b4"/></a>
            <a href="tel:+972507750600"><FaPhone  size={30} color="#ffb4b4"/></a>

          </div>
          </div>

          </Fade>

          <Fade right duration={2500}>

          <div className="block5">

          <h1 style={{fontSize: t('Font')}}>{t('Send')}</h1>
                <p>{t('SendP')}</p>

                <form ref={form}>

                <div className="info">
                    <input placeholder={t('Name')} type="Name" name="name"/>
                    <input placeholder={t('Phone')} type="phone" name="phone"/>
                    <input placeholder={t('Email')} type="email" name="email"/>
                </div>
                <textarea placeholder={t('Message')} name="message"/>

                </form>

                <button onClick={sendEmail}>{t('Send2')}</button>
            
          </div>

          </Fade>

        </section>

        <section className="sec-5">
            
                <img src={Logo} alt="image1" onContextMenu={(e)=>e.preventDefault()} onDragStart={(e)=>{e.preventDefault()}}/>

                <button onClick={() => {nav('Terms')}}>{t('Terms')}</button>

                <p>Powered By <a href="https://devstation.pro/">devstation.pro</a></p>


            </section>

        </React.Fragment>
    )

}

export default Home