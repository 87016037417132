import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Book from "./components/Book";
import Terms from "./components/Terms";
import Services from "./components/Services";
import Payment from './components/Payment'
import ViewBookings from "./components/ViewBookings";
import AdminBook from "./components/AdminBook";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='Book' element={<Book/>}/>
      <Route path='Terms' element={<Terms/>}/>
      <Route path='Services' element={<Services/>}/>
      <Route path='Payment' element={<Payment/>}/>
      <Route path='AdminView' element={<ViewBookings/>}/>
      <Route path='/AdminView/AdminBook' element={<AdminBook/>}/>
    </Routes>
  )
}

export default App;
