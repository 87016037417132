import React, { useState, useEffect } from "react";
import { db } from './firebase';
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import '../Styles/Admin/Admin.css'

const ViewBookings = () => {

  const [todaysBookings, setTodaysBookings] = useState([]);
  const [dailyBookings, setDailyBookings] = useState({});

  const nav = useNavigate()

  useEffect(() => {
    const fetchTodaysBookings = async () => {
      const today = new Date();
      const formattedToday = today.toISOString().split('T')[0];

      try {
        const snapshot = await db
          .collection('bookings')
          .where('date', '==', formattedToday)
          .get();

        const todayBookings = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTodaysBookings(todayBookings);
      } catch (error) {
        console.error('Error fetching today\'s bookings:', error);
      }
    };

    fetchTodaysBookings();

    const fetchDailyBookings = async () => {
      try {
        const snapshot = await db.collection('bookings').get();

        const bookingsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const groupedBookings = bookingsData.reduce((acc, booking) => {
          const date = booking.date;

          if (!acc[date]) {
            acc[date] = [];
          }

          acc[date].push(booking);

          return acc;
        }, {});

        setDailyBookings(groupedBookings);
      } catch (error) {
        console.error('Error fetching daily bookings:', error);
      }
    };

    fetchDailyBookings();
  }, []);

  const handleDeleteBooking = async (date, id) => {
    try {
      await db.collection('bookings').doc(id).delete();
      setDailyBookings((prevDailyBookings) => {
        const updatedBookings = { ...prevDailyBookings };
        updatedBookings[date] = updatedBookings[date].filter((booking) => booking.id !== id);
        return updatedBookings;
      });
      console.log('Booking deleted successfully!');
      window.location.reload()
    } catch (error) {
      console.error('Error deleting booking:', error);
    }
  };

  return (
    <React.Fragment>
      <div className="A1">
        <h1>Admin</h1>
      </div>

      <div className="A2">
        
        <button onClick={() => {nav('/AdminView/AdminBook')}}>Add new</button>
      </div>

      <div className="Bookings">
        <h1>Today</h1>
        <div className="data">
          {todaysBookings.map((booking) => (
            <li key={booking.id} className="order">
              {`Name: ${booking.name}, Service: ${booking.service}, Phone: ${booking.phoneNumber}, Time: ${booking.time}`}
              <button title="Delete" onClick={() => handleDeleteBooking(booking.date, booking.id)}><FaTrash size={20} color="red"/></button>
            </li>
          ))}
        </div>
      </div>

      <div className="Bookings">
        <h1>All Bookings</h1>
        <div>
          {Object.entries(dailyBookings).map(([date, bookings]) => (
            <div key={date} className="Bookings">
              <h2>{`Bookings for ${date}`}</h2>
              <ul>
                {bookings.map((booking) => (
                  <li key={booking.id} className="order">
                    {`Name: ${booking.name}, Service: ${booking.service}, Phone: ${booking.phoneNumber}, Time: ${booking.time}`}
                    <button title="Delete" onClick={() => handleDeleteBooking(date, booking.id)}><FaTrash size={20} color="red"/></button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewBookings;