import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Styles/Translation.css'

const LanguageSwitcher = ({fun}) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('Lang', lng);
    fun()
  };

  return (
    <div className='Maind'>
      <button onClick={() => {changeLanguage('en')}}>English</button>
      <button onClick={() => {changeLanguage('ar')}}>العربية</button>
      <button onClick={() => {changeLanguage('he')}}>עברית</button>
    </div>
  );
};

export default LanguageSwitcher;
